import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FullScreenOverlay } from '@bibliocommons/base-full-screen-overlay';
import { selectOpenOverlays } from 'app/selectors/ui/FullScreenOverlaySelector';

export function FullScreenOverlayContainer({ overlayKey, open, ...props }) {
  return <FullScreenOverlay open={open} key={overlayKey} {...props} />;
}

FullScreenOverlay.defaultProps = {
  disableClose: false
};

FullScreenOverlayContainer.propTypes = {
  dataKey: PropTypes.string.isRequired,
  disableClose: PropTypes.bool,
  handleClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  overlayKey: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  open: ownProps.open || selectOpenOverlays(state).has(ownProps.overlayKey)
});

export default connect(mapStateToProps)(FullScreenOverlayContainer);
