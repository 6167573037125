import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import Datestamp from '@bibliocommons/date-stamp';

export default function EventDateStamp({ event, isCalloutFeatured }) {
  const month = moment(event.getIn(['definition', 'start'])).format('MMM');
  const day = moment(event.getIn(['definition', 'start'])).format('D');

  return (
    <Datestamp className="cp-event-date-stamp" month={month} day={day} size={isCalloutFeatured ? 'large' : 'default'} />
  );
}

EventDateStamp.propTypes = {
  event: ImmutablePropTypes.map.isRequired,
  isCalloutFeatured: PropTypes.bool
};
