import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Divider from '@bibliocommons/divider';
import FacetCategory from './FacetCategory';
import './FacetList.scss';

export default function FacetList({
  categories,
  facetFields,
  handleToggleFilter,
  handleShowMore,
  activeFilters,
  handleCategoryToggle,
  singleBranch,
  facetFilterComponent
}) {
  const indexedFields = facetFields.reduce((result, field) => result.set(field.get('id'), field), Immutable.Map());

  function renderCategory(category, index) {
    const field = indexedFields.get(category);
    if (!field || (field.get('groups') && field.get('groups').isEmpty())) {
      return null;
    }

    return (
      <div key={field.get('id')}>
        {index > 0 && <Divider className="category-divider" />}
        <FacetCategory
          field={field}
          activeFilters={activeFilters}
          handleToggleFilter={handleToggleFilter}
          handleShowMore={handleShowMore}
          handleCategoryToggle={handleCategoryToggle}
          singleBranch={singleBranch}
          facetFilterComponent={facetFilterComponent}
        />
      </div>
    );
  }
  return <div className="cp-facet-list">{categories.map(renderCategory)}</div>;
}

FacetList.propTypes = {
  categories: PropTypes.array.isRequired,
  facetFields: ImmutablePropTypes.list.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  handleShowMore: PropTypes.func.isRequired,
  activeFilters: ImmutablePropTypes.map.isRequired,
  handleCategoryToggle: PropTypes.func,
  singleBranch: PropTypes.bool,
  facetFilterComponent: PropTypes.string
};
