import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';
import { FormattedHTMLMessage } from '@bibliocommons/react-intl';
import {
  FullScreenOverlayBody,
  FullScreenOverlayHeader,
  FullScreenOverlayContent
} from '@bibliocommons/base-full-screen-overlay';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { BackLink } from '@bibliocommons/base-links';
import Filter from '../Filter';

import './ShowMoreOverlay.scss';

export default function ShowMoreOverlay({ fieldId, filters, handleToggleFilter, handleAllFilters, subCategory }) {
  function renderFilters(filter) {
    return (
      <Filter
        fieldId={fieldId}
        filter={filter}
        handleToggleFilter={handleToggleFilter}
        key={`filter-${fieldId}-${filter.get('value')}`}
        subCategory={subCategory}
      />
    );
  }

  return (
    <fieldset className="cp-show-more-overlay">
      <FullScreenOverlayBody>
        <FullScreenOverlayHeader title={<FormattedHTMLMessage id={`all_${fieldId.toLowerCase()}`} />} />
        <FullScreenOverlayContent size="small">
          {handleAllFilters && (
            <BackLink
              className="all-filters-link"
              dataKey="all-filters-link"
              href="#all-filters"
              preventDefault
              handleClick={handleAllFilters}
            >
              <span aria-hidden>
                <FormattedMessage id="all_filters" />
              </span>
              <ScreenReaderMessage>
                <FormattedMessage id="sr_all_filters" />
              </ScreenReaderMessage>
            </BackLink>
          )}
          <div className="show-more-content">
            <ul className="filter-list">{filters.map(renderFilters)}</ul>
          </div>
        </FullScreenOverlayContent>
      </FullScreenOverlayBody>
    </fieldset>
  );
}

ShowMoreOverlay.propTypes = {
  fieldId: PropTypes.string.isRequired,
  filters: ImmutablePropTypes.listOf(ImmutablePropTypes.map.isRequired).isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  handleAllFilters: PropTypes.func,
  subCategory: ImmutablePropTypes.map
};
