import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useIntl, FormattedMessage } from 'react-intl';
import Checkbox from '@bibliocommons/base-checkbox';
import RadioButton from '@bibliocommons/base-radio-button';
import { RADIO_BUTTON_FILTER } from 'app/constants/FacetsConstants';

import './Filter.scss';

export default function Filter({ fieldId, filter, subCategory, handleToggleFilter, facetFilterComponent = '' }) {
  const intl = useIntl();
  const filterLabel = `${filter.get('label')} (${intl.formatNumber(filter.get('count'))})`;
  const radioButtonLabel = filter.get('localization') ? (
    <FormattedMessage id={filter.get('localization')} />
  ) : (
    filter.get('label')
  );

  function renderFilter() {
    switch (facetFilterComponent) {
      case RADIO_BUTTON_FILTER: {
        return (
          <RadioButton
            className="filter-radiobutton"
            id={filter.get('value')}
            label={radioButtonLabel}
            checked={filter.get('applied')}
            name="event-date"
            handleChange={() => handleToggleFilter(filter)}
          />
        );
      }

      default: {
        return (
          <Checkbox
            className="filter-checkbox"
            id={filter.get('value')}
            label={filterLabel}
            checked={filter.get('applied')}
            handleChange={() => handleToggleFilter(fieldId, filter, subCategory)}
          />
        );
      }
    }
  }

  return (
    <li className="cp-facet-filter" key={filter.get('value')}>
      {renderFilter()}
    </li>
  );
}

Filter.propTypes = {
  fieldId: PropTypes.string.isRequired,
  filter: ImmutablePropTypes.mapContains({
    label: PropTypes.string.isRequired,
    applied: PropTypes.bool,
    count: PropTypes.number,
    value: PropTypes.string.isRequired
  }).isRequired,
  subCategory: ImmutablePropTypes.map,
  handleToggleFilter: PropTypes.func.isRequired,
  facetFilterComponent: PropTypes.string
};
