import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';
import Context from '@bibliocommons/context';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { Link } from '@bibliocommons/base-links';

import './EventLocation.scss';

export default function EventLocation({ location, isVirtual, handleLocationClick }) {
  const { mobileApp } = useContext(Context);

  const label = isVirtual ? (
    <FormattedMessage id="online_event" />
  ) : (
    <>
      <span aria-hidden>{location?.get('name')}</span>
      <ScreenReaderMessage>
        <FormattedMessage id="sr_event_location" values={{ locationName: location?.get('name') }} />
      </ScreenReaderMessage>
    </>
  );

  const url = !mobileApp || !isVirtual ? location?.get('webUrl') || location?.get('url') : null;

  return (
    <div className="cp-event-location">
      {url ? (
        <Link href={url} dataKey="event-location-link" handleClick={handleLocationClick}>
          {label}
        </Link>
      ) : (
        <span>{label}</span>
      )}
    </div>
  );
}

EventLocation.propTypes = {
  location: ImmutablePropTypes.map,
  isVirtual: PropTypes.bool,
  handleLocationClick: PropTypes.func
};
