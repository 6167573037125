import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { useRouter } from '@bibliocommons/utils-routing';
import AccessibleDatePicker from '@bibliocommons/base-accessibility-date-picker';
import { TextButton } from '@bibliocommons/base-buttons';
import ShortFormattedDate from 'app/components/shared/ShortFormattedDate';

import './DateField.scss';

export function DateField({ handleToggleFilter }) {
  const router = useRouter();
  const { startDate, endDate } = router.query;
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

  function formatDate(date) {
    if (!date) return undefined;
    return moment(date).format('YYYY-MM-DD');
  }

  function clearDates() {
    const query = { ...router.query };
    delete query.startDate;
    delete query.endDate;
    delete query.page;
    router.push({ query });
  }

  return (
    <div className="cp-date-field">
      <p id="date_from" className="date-picker-label">
        <FormattedMessage id="date_from" />
      </p>
      <div className="date-picker-container">
        <AccessibleDatePicker
          open={openStartDatePicker}
          handleClose={() => setOpenStartDatePicker(false)}
          onDateChange={dateValue => handleToggleFilter('startDate', Immutable.Map({ value: formatDate(dateValue) }))}
          minDate={moment()}
          initialDate={startDate}
          handleOpen={() => setOpenStartDatePicker(true)}
          id="events-start-date-picker"
          dataKey="date-picker-button"
          ariaDescribedBy="date_from"
        >
          <ShortFormattedDate value={startDate || formatDate(moment())} />
        </AccessibleDatePicker>
      </div>
      <p id="date_until" className="date-picker-label">
        <FormattedMessage id="date_until" />
      </p>
      <div className="date-picker-container">
        <AccessibleDatePicker
          open={openEndDatePicker}
          handleClose={() => setOpenEndDatePicker(false)}
          onDateChange={dateValue => handleToggleFilter('endDate', Immutable.Map({ value: formatDate(dateValue) }))}
          minDate={moment()}
          initialDate={endDate}
          handleOpen={() => setOpenEndDatePicker(true)}
          id="events-until-date-picker"
          dataKey="date-picker-button"
          ariaDescribedBy="date_until"
        >
          {endDate ? <ShortFormattedDate value={endDate} /> : <FormattedMessage id="select_a_date" />}
        </AccessibleDatePicker>
      </div>
      {(!!startDate || !!endDate) && (
        <TextButton handleClick={clearDates} dataKey="clear-dates-button" leftAligned>
          <FormattedMessage id="clear_dates" />
        </TextButton>
      )}
    </div>
  );
}

DateField.propTypes = {
  handleToggleFilter: PropTypes.func.isRequired
};

export default DateField;
