import Immutable from 'immutable';
import { createSelector } from 'reselect';

export const selectOpenOverlays = createSelector(
  [state => state.getIn(['ui', 'fullScreenOverlay'], Immutable.Map())],
  overlays => overlays.filter(overlay => !!overlay.get('open'))
);

export default {
  selectOpenOverlays
};
