import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { EVENTS_FACET_FIELDS } from 'app/constants/EventsConstants';
import CategoryHeading from '../CategoryHeading';
import FacetSubCategory from '../FacetSubCategory';
import DateField from '../DateField';

import './FacetCategory.scss';

export default function FacetCategory({
  field,
  handleToggleFilter,
  handleShowMore,
  activeFilters,
  handleCategoryToggle,
  singleBranch,
  facetFilterComponent
}) {
  const [collapsed, setCollapsed] = useState(field.get('collapsed'));

  function handleToggle() {
    if (handleCategoryToggle) handleCategoryToggle(collapsed, field);
    setCollapsed(!collapsed);
  }

  function renderSubCategory(subCategory) {
    return (
      <FacetSubCategory
        key={`facet-category-${subCategory.get('groupId')}`}
        fieldId={field.get('id')}
        subCategory={subCategory}
        handleToggleFilter={handleToggleFilter}
        handleShowMore={handleShowMore}
        singleBranch={singleBranch}
        facetFilterComponent={facetFilterComponent}
      />
    );
  }

  function renderContents() {
    switch (field.get('id')) {
      case EVENTS_FACET_FIELDS.EVENT_DATES:
        return <DateField handleToggleFilter={handleToggleFilter} />;
      default:
        return field.get('groups').map(renderSubCategory);
    }
  }

  return (
    <fieldset className="cp-facet-category" key={field.get('id')}>
      <CategoryHeading
        fieldId={field.get('id')}
        title={field.get('labelId')}
        collapsed={collapsed}
        handleToggle={handleToggle}
        activeFilters={activeFilters}
        facetFilterComponent={facetFilterComponent}
      />

      {!collapsed && <div className="facet-category-contents">{renderContents()}</div>}
    </fieldset>
  );
}

FacetCategory.propTypes = {
  field: ImmutablePropTypes.map.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  handleShowMore: PropTypes.func.isRequired,
  activeFilters: ImmutablePropTypes.map.isRequired,
  handleCategoryToggle: PropTypes.func,
  singleBranch: PropTypes.bool,
  facetFilterComponent: PropTypes.string
};
