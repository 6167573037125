import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';
import { ChevronDown, ChevronRight } from '@bibliocommons/base-icons';
import { Badge } from '@bibliocommons/base-badges';
import Heading from '@bibliocommons/heading';

import './CategoryHeading.scss';

export default function CategoryHeading({
  fieldId,
  title,
  collapsed,
  handleToggle,
  activeFilters,
  facetFilterComponent
}) {
  function renderIcon() {
    return collapsed ? <ChevronRight className="chevron-icon" /> : <ChevronDown className="chevron-icon" />;
  }

  function renderFilterCount() {
    if (facetFilterComponent) return null;

    const filterCount = activeFilters.get(fieldId)?.size;
    if (filterCount) {
      return (
        <Badge size="small" rounded>
          {filterCount}
        </Badge>
      );
    }
    return null;
  }

  return (
    <div className="cp-category-heading">
      <button type="button" onClick={handleToggle} aria-expanded={!collapsed}>
        {renderIcon()}

        <Heading tagName="fieldset" size="modest" className="category-name">
          <legend className="category-heading">
            <FormattedMessage id={title} />
          </legend>
        </Heading>
        {renderFilterCount()}
      </button>
    </div>
  );
}

CategoryHeading.propTypes = {
  fieldId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  activeFilters: ImmutablePropTypes.map.isRequired,
  facetFilterComponent: PropTypes.string
};
