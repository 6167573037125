import moment from 'moment';
import compact from 'lodash/compact';

export default function getMomentAtLocation(location, dateTimeStr, localNow) {
  const utcOffset = location?.getIn(['mapLocation', 'utcOffsetNow']);
  if (!utcOffset) return null;

  if (dateTimeStr) {
    let localDateTimeStr = dateTimeStr;
    const [dateStr, timeStr] = dateTimeStr.split('T');
    let format = compact([dateStr ? 'YYYY-MM-DD' : undefined, timeStr ? 'THH:mm' : undefined]).join('');

    if (!dateStr && localNow) {
      const localDate = `${moment(localNow).get('year')}-${moment(localNow).get('month') + 1}-${moment(localNow).get(
        'date'
      )}`;
      localDateTimeStr = `${localDate}${dateTimeStr}`;
      format = compact([localDate ? 'YYYY-MM-DD' : undefined, timeStr ? 'THH:mm' : undefined]).join('');
    }
    return moment.parseZone(`${localDateTimeStr}${utcOffset}`, `${format}Z`);
  }

  return moment().utcOffset(utcOffset);
}
