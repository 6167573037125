import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';
import { TextButton } from '@bibliocommons/base-buttons';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import Filter from '../../Filter';

import './FacetSubCategory.scss';

const FACET_FILTERS_LIMIT = 10;

export default function FacetSubCategory({
  fieldId,
  subCategory,
  handleToggleFilter,
  handleShowMore,
  singleBranch,
  facetFilterComponent
}) {
  function renderFilters(filter, index) {
    if (index < FACET_FILTERS_LIMIT || filter.get('applied')) {
      return (
        <Filter
          fieldId={fieldId}
          filter={filter}
          handleToggleFilter={handleToggleFilter}
          key={`filter-${fieldId}-${filter.get('value')}`}
          subCategory={subCategory}
          facetFilterComponent={facetFilterComponent}
        />
      );
    }
    return null;
  }

  function renderShowMoreButton() {
    return (
      <TextButton
        className="show-more"
        dataKey={`show-more-${fieldId}`}
        handleClick={() => handleShowMore(fieldId, subCategory.get('groupId'))}
        leftAligned
      >
        <span aria-hidden>
          <FormattedMessage id="button_show_more" />
        </span>
        <ScreenReaderMessage>
          <FormattedMessage id="sr_show_more_opens_overlay" />
        </ScreenReaderMessage>
      </TextButton>
    );
  }

  return (
    <div className="cp-facet-subcategory" key={subCategory.get('groupId')}>
      {subCategory.get('labelId') && !singleBranch && (
        <div className="subcategory-title">
          <FormattedMessage id={subCategory.get('labelId')} />
        </div>
      )}
      <div className="field-group">
        <ul className="filter-list">{subCategory.get('filters').map(renderFilters)}</ul>
      </div>
      {subCategory.get('filters').size > FACET_FILTERS_LIMIT && renderShowMoreButton()}
    </div>
  );
}

FacetSubCategory.propTypes = {
  fieldId: PropTypes.string.isRequired,
  subCategory: ImmutablePropTypes.mapContains({
    groupId: PropTypes.string.isRequired,
    labelId: PropTypes.string,
    expanded: PropTypes.bool,
    filters: ImmutablePropTypes.listOf(ImmutablePropTypes.map.isRequired).isRequired
  }).isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  handleShowMore: PropTypes.func.isRequired,
  singleBranch: PropTypes.bool,
  facetFilterComponent: PropTypes.string
};
